@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter";
}
nav {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #212529;
}
header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}
.navbar-collapse {
  text-align: center;
}

.navbar .navbar-toggler:focus {
  color: #212529 !important;
  box-shadow:none;
  outline:none;
}
.navbar .navbar-toggler-icon i {
  color: white;
  font-size: 30px;
  padding-left: 10px;
}
.navbar .navbar-toggler {
  padding: 0;
}

.nav-link,
.navbar-brand {
  color: #f8f9fa;
}

nav .navbar-brand {
  font-size: 200%;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
}

nav .nav-link {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
}
nav .nav-item {
  padding: 0 10px;
}

.videoplay video {
  width: 100%;
}
.videoplay {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  position: relative;
  margin-top: 64px
}

#about {
  background-color: #dee2e6 !important; 
}

.swiper {
  width: 100%;
  object-fit: cover;
  position: relative;
  display:flex;
  align-items:center;
  justify-content:center;
  height:100vh;
}

.swiper-slide {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dee2e6;
}

.swiper-slide img {
  display: block;
  border-radius: 4%;
  /* width:70%; */
  
}

.carddiv {
  width:100%;
  height:100vh;
  padding: 5% 0;
  background-color: #dee2e6;
  display:flex;
  align-items:center;
  justify-content:center;
}
.carddiv .row {
  width: 980px;
}
.carddiv h1 {
  font-size: 64px;
  font-weight: 600;
  line-height: 77px;
  letter-spacing: 0em;
}
.carddivhead {
  width: 650px;
  padding: 1%;
}
.carddiv .card {
  width: 100%;
  height: 100%;
  padding: 20% 10% 15% 10%;
  background-color: #202020;
  color: white;
  border-radius: 20px;
  border: none;
  box-shadow: 10px 10px 15px 0px #00000040;
}
.carddiv .card h3 {
  margin: 8% 0;
  /* padding-bottom:3%; */
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
}
.carddiv .card h5 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.carddiv .cardcont h5 {
    line-height: 1.8;
}
.contact{
  background: #d9d9d9;
  display:flex;
  align-items:center;
  justify-content:center;
  width:100%;
  height:100vh;
}

.contact input {
  padding: 12px;
  background: #212529;
  border-radius: 10px;
  color: white;
  padding-left: 17px;
  border: none;
}
.contact textarea {
  background: #212529;
  border-radius: 10px;
  color: white;
  border: none;
  padding: 15px;
  padding-left: 17px;
}
.contact input:focus {
  outline: none;
  background: #212529;
  color: white;
  box-shadow:none;
}
.contact textarea:focus {
  outline: none;
  background: #212529;
  color: white;
  box-shadow:none;
}
.contact h1 {
  font-size: 64px;
  font-weight: 600;
  line-height: 77px;
  letter-spacing: 0em;
  text-align: center;
}
.contact form h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

form .btn {
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #212529;
  background: #f8f9fa;
}
form .btn:hover {
  background: white;
}
.smedia h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}


.contacticon i {
  font-size: 27px;
  color: #212529;
  margin-right: 15px;
}
.contacticon a {
  text-decoration: none;
  color: #212529;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 11px 0px 5px 0px;
}
.allcontact {
  width: 992px;
}

.contacticon {
  display: flex;
}
.contacticon .iconbox {
  display: flex;
  flex-direction: column;
}
.footerarea {
  width: 100%;
  height: 70px;
  background: #212529;
}
@media only screen and (max-width: 1070px) {
  .allcontact{
    padding:0 2%;
  }
}

@media only screen and (max-width: 992px) {
  .allcontact {
    width: 100%;
    padding:0 2.7%;
  }
  .contact{
    width:100%;
    height:auto;
    padding:5% 0;
  }
  .carddivhead {
    width:95%;
  }
  .carddiv .row {
    width: 100%;
  }
  .carddiv{
    width:100%;
    height:auto;
  }
  .swiper{
    width:100%;
    height:auto;
    padding-top: 40px;
  }
  .swiper-slide img {
    padding:4% 0;
    width:60%;
    border-radius: 10%;
  }
  .videoplay video {
    margin-top: -10px;
  }
  .swiper-slide{
    height:350px;
  }
}
@media only screen and (max-width: 768px) {
  .smedia {
    margin: 0;
  }
  .swiper-pagination-bullet{
    width:6px;
    height:6px;
  }
}

@media only screen and (max-width: 576px) {
  header .navbar {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
  nav .navbar-brand {
    font-size: 100%;
  }
  nav .navbar-collapse .nav-link {
    font-size: 17px;
  }
  .contacticon .iconbox i {
    font-size: 17px;
    margin-right: 7px;
  }
  .contacticon .iconbox a {
    font-size: 16px;
  }
  .carddiv .carddivhead h1 {
    font-size: 45px;
  }
  .contact .contacthead h1 {
    font-size: 45px;
  }
  .swiper-slide img {
    display: block;
    width:85%;
    border-radius: 10%;
    /* height:200px; */
  }
  .swiper-slide{
    height:220px;
  }
}
@media only screen and (max-width: 1050px) {
  /* .videoplay {
    width: 100%;
    height: auto;
  } */
}
